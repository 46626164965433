import { getContext, put, takeLatest } from 'redux-saga/effects';
import { get, pick } from 'lodash';
import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';
import { getLoggerFromContext } from '../utils/getLoggerFromContextSaga';

const prepareAdminData = (data) => {
    if (!data.login) {
        return null;
    }

    return {
        companyId: null,
        externalSubCompanyId: null,
        ...data
    };
};

// WATCHERS
export function* adminSaga() {
    yield takeLatest(actions.getAdminList, getAdminList);
    yield takeLatest(actions.createAdmin, createAdmin);
    yield takeLatest(actions.updateAdmin, updateAdmin);
    yield takeLatest(actions.deleteAdmin, deleteAdmin);
}

export function* getCurrentAdmin() {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_CURRENT_ADMIN, {});
        const preparedData = prepareAdminData(res.body);

        yield put(actions.getCurrentAdminSuccess(preparedData));
    } catch (err) {
        yield put(actions.getCurrentAdminFailure(err));
    }
}

export function* handleAdminData(msg) {
    try {
        const preparedData = prepareAdminData(msg.body);
        yield put(actions.gotAdminData(preparedData));
    } catch (err) {
        const logger = yield getLoggerFromContext('Admin saga');
        logger.log('Handle admin data message error: %O', err);
    }
}

export function* getAdminList({ payload = {} }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    const options = pick(payload, ['companyId', 'login']);

    const data = { ...options, withDeleted: false };

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_ADMIN_LIST, data);
        const admins = get(res, 'body.admin', []);

        const preparedData = admins.map((a) => prepareAdminData(a));

        yield put(actions.getAdminListSuccess(preparedData));
    } catch (err) {
        yield put(actions.getAdminListFailure(`${err}`));
    }
}

export function* createAdmin({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const admin = pick(payload, ['login', 'name', 'email', 'companyId', 'password', 'subCompanyId', 'roles']);

        const adminData = {
            ...admin,
            subCompanyId: admin.subCompanyId === null ? [] : admin.externalSubCompanyId
        };

        const res = yield fastCityService.request(FC_REQUESTS.CREATE_ADMIN, adminData);
        const preparedData = prepareAdminData(res.body);

        yield put(actions.createAdminSuccess(preparedData));
    } catch (err) {
        yield put(actions.createAdminFailure(`${err}`));
    }
}

export function* updateAdmin({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const admin = pick(payload, ['login', 'name', 'email', 'password', 'roles', 'subCompanyId']);

        const res = yield fastCityService.request(FC_REQUESTS.UPDATE_ADMIN, admin);
        const preparedData = prepareAdminData(res.body);

        yield put(actions.updateAdminSuccess(preparedData));
    } catch (err) {
        yield put(actions.updateAdminFailure(`${err}`));
    }
}

export function* deleteAdmin({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const admin = pick(payload, ['login']);

        const res = yield fastCityService.request(FC_REQUESTS.DELETE_ADMIN, admin);
        const preparedData = prepareAdminData(res.body);

        yield put(actions.deleteAdminSuccess(preparedData));
        yield put(actions.getAdminList());
    } catch (err) {
        yield put(actions.deleteAdminFailure(`${err}`));
    }
}
